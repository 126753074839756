import { FETCH, SET, FETCH_SUCCESS, FETCH_FAILURE } from './actionTypes';

const set = (payload?: any) => {
  return {
    type: SET,
    payload
  }
};


const fetch=(payload: any) =>{
  return {
    type: FETCH,
    payload
  }
}

  const fetchSuccess=(payload: any) =>{
  return {
    type: FETCH_SUCCESS,
    payload
  }
}

  const fetchFail=(payload: any) =>{
  return {
    type: FETCH_FAILURE,
    payload
  }
}

const HeaderActions = {
  set,
  fetchSuccess,
  fetchFail,
  fetch

}

export default HeaderActions;

