import { FETCH, SET, FETCH_SUCCESS, 
  FETCH_FAILURE, SET_USER, 
  SET_IS_AUTHENTICATED,
SET_REDIRECTED_LOGIN,
DISABLED_REDIRECT_LOGIN,
GET_PARAMETERS,
SET_PARAMETERS } from './actionTypes';
import { API, Auth } from 'aws-amplify';    

export function setAuthenticated(payload: any) {
  return {
    type: 'SET_IS_AUTHENTICATED',
    payload
  }
}

export async function checkIfIsAuthenticated(dispatch:any){
  console.log("Hemos ingresado a validar si esta authenticado");
  // const {authenticated} = await  Auth.currentUserCredentials();
  try{
    let {attributes} = await Auth.currentAuthenticatedUser()
  console.log("attributes",attributes)
  if(attributes){
    console.log("attributes", attributes)
    let cod_roltercero =attributes['custom:codroltercero'];
    const resultadoTercero = await API.post(process.env.REACT_APP_ENDPOINT_NAME, `/tercero/link/obtener-datos`, {
      body: {
        "request":{
          "payload":{
            "cod_roltercero": cod_roltercero	
          }
        }
      }
    })
    console.log("resultadoTercero", resultadoTercero)
    dispatch({
      type: SET_USER,
      payload: resultadoTercero
    } )
    return true
  }


  }catch(e){
      dispatch({
        type: SET_IS_AUTHENTICATED,
        payload: false
      })
      return false
  }
}

export async function logout(dispatch:any){
try{
  await Auth.signOut();

  let veamos = await Auth.currentSession();
  console.log("El userState papu", veamos)
  dispatch({
    type: SET_IS_AUTHENTICATED,
    payload: false
  })

}catch(e){
  dispatch({
    type: SET_IS_AUTHENTICATED,
    payload: false
  })
}


}

export function setUserInfo(userInfo: any){
  return {
    type: SET_USER,
    payload: userInfo
  }

}

export function set(payload: any) {
  return {
    type: SET,
    payload
  }
}

export function fetch(payload: any) {
  return {
    type: FETCH,
    payload
  }
}

export function fetchSuccess(payload?: any) {
  return {
    type: FETCH_SUCCESS,
    payload
  }
}

export function fetchFail(payload: any) {
  return {
    type: FETCH_FAILURE,
    payload
  }
}

export function setRedirectLogin(payload: any) {
  return {
    type: SET_REDIRECTED_LOGIN,
    payload
  }
}

export function disabledRedirectLogin(){
  return {
    type: DISABLED_REDIRECT_LOGIN
  }
}

// export async  function getParameters(dispatch:any){
//   // localStorage.setItem('bgcolor', 'red');
//   // localStorage.getItem('')
//   console.log("inicio el getParameters")
//   let parameter_version = localStorage.getItem('parameter_version') || '0.0.0'
//   const resultadoParametros = await API.post(process.env.REACT_APP_ENDPOINT_NAME ,
//     `/link/parameters`, {
//     body: {
//       "request":{
//         "payload":{
//           "app_type": "APP_WEB_CLIENT",
//           "parameter_version": parameter_version 
//         }
//       }
//     }
//   })
//   let payload = resultadoParametros.response.payload
//   if(payload.updateAgain){
//     console.log("Se realizo el cambio")
//     // Object.keys(payload.parameters).forEach(parameter=>{
//     //   localStorage.setItem(parameter, JSON.stringify(payload.parameters[parameter]));
//     // })
//     localStorage.setItem("parameters", JSON.stringify(payload.parameters));

//     localStorage.setItem("parameter_version",payload.parameter_version)
//   }
//   console.log("resultadoParametros123", resultadoParametros)
//   dispatch({
//     type: SET_PARAMETERS,
//     payload: payload.parameters
//   } )
//   return true
// }


// export async function getParameters(dispatch:any){
//   try{
//     await Auth.signOut();
  
//     let veamos = await Auth.currentSession();
//     console.log("El userState papu", veamos)
//     dispatch({
//       type: SET_IS_AUTHENTICATED,
//       payload: false
//     })
  
//   }catch(e){
//     dispatch({
//       type: SET_IS_AUTHENTICATED,
//       payload: false
//     })
//   }
  
  
//   }
  
