import { Observable, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { FETCH } from './actionTypes';
import { fetchSuccess, fetchFail } from './actions';


//Epics
const fetchEpic = (action$: any) => action$.pipe(
  ofType(FETCH),
  mergeMap( (action: any) => {
    const promise = new Promise((resolve, reject) => setTimeout(() => resolve("done"), 1000));
    return from(promise).pipe(
      map(fetchSuccess),
      catchError(error => of(fetchFail(error)))
    );
  })
);

export default [
  fetchEpic
]
