import { OPEN_LOADING, CLOSE_LOADING, OPEN_LOADING_SUCCESS } from './actionTypes';
import IState from './state.model';
import IAction from '../../models/action.model';

const defaultStore: IState = {
  isLoading: false,
  isAvailable: false
}

export default function loading(
  state:IState = defaultStore,
  action: any,
) {
  switch (action.type) {
    case OPEN_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: true
      }  

    case OPEN_LOADING:
      return {
        ...state,
        isAvailable: true
      } 
    
    case CLOSE_LOADING:
      return {
        isLoading: false,
        isAvailable: false
      } 

    default:
      return state;
  }
}
