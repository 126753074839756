import { combineEpics } from 'redux-observable';

import headerEpics from './header/epics';
import userEpics from './user/epics';
import loadingEpics from './loading/epics';
import alertEpics from './alert/epics';
const rootEpic = combineEpics(
  ...userEpics,
  ...loadingEpics,
  ...headerEpics,
  ...alertEpics
);

export default rootEpic;
