import { 
  ADD_ALERT, 
  ADD_ALERT_SUCCESS,
  ADD_ALERT_DANGER,
  ADD_ALERT_WARNING,
  REMOVE_ALERT
 } from './actionTypes';
import IAction from '../../models/action.model';

const defaultStore: any = {
  isActive: false
}

export default function alert(
  state: any = defaultStore,
  action: IAction,
) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        isActive: true,
        payload: action.payload
      }  
      break;
    case ADD_ALERT_SUCCESS:
      return {
        isActive: true,
        payload: {
          ...action.payload,
          className: 'is-success'
        }
      } 
      break;
    case ADD_ALERT_WARNING:
      return {
        isActive: true,
        payload: {
          ...action.payload,
          className: 'is-warning'
        }
      } 
      break;
    case ADD_ALERT_DANGER:
      return {
        isActive: true,
        payload: {
          ...action.payload,
          className: 'is-danger'
        }
      } 
      break;
    case REMOVE_ALERT:
      return {
        ...state,
        isActive:false
      } 
      break;

    default:
      return state;
  }
}
