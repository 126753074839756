import { ofType } from 'redux-observable';
import { delay, mapTo, filter, map } from 'rxjs/operators';
import { OPEN_LOADING } from './actionTypes';
import LoadingActions from './actions';

const loadingEpic = (action$: any, state$:any) => action$.pipe(
  ofType(OPEN_LOADING),
  delay(5000), // change wait time for loading
  filter(() => state$.value.loading.isAvailable),
  // map(() => AdobeAnalytics.track_login_error(
  //     ADOBE_ANALYTICS.ERROR_CODE.ALL.CODE,
  //     ADOBE_ANALYTICS.ERROR_MESSAGE + ' - ' + ADOBE_ANALYTICS.ERROR_CODE.ALL.NAME,
  //     ADOBE_ANALYTICS.EVENTS.TRACK_ERROR)),
  mapTo(LoadingActions.openSuccess()),
);

export default [
  loadingEpic
]
