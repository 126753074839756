import { 
  ADD_ALERT, 
  ADD_ALERT_SUCCESS,
  ADD_ALERT_DANGER,
  ADD_ALERT_WARNING,
  REMOVE_ALERT
} from './actionTypes';

interface IPayload {
  message: string ,
  description: string,
  isHtml?: boolean
}


const addAlert = (payload?: any) => {
  return {
    type: ADD_ALERT,
    payload
  };
};

const success = (payload?: any) => {
  return {
    type: ADD_ALERT_SUCCESS,
    payload
  };
};

const danger = (payload?: any) => {
  return {
    type: ADD_ALERT_DANGER,
    payload
  };
};

const warning = (payload?: any) => {
  return {
    type: ADD_ALERT_WARNING,
    payload
  };
};
const remove = (payload?: any) => {
  return {
    type: REMOVE_ALERT,
    payload:{
      message: '',
      description: ''
    }
  };
};

const LoadingActions = {
  addAlert,
  success,
  danger,
  warning,
  remove
}

export default LoadingActions;
