import { ofType } from 'redux-observable';
import { delay, mapTo, filter, map } from 'rxjs/operators';
import { 
  ADD_ALERT, 
  ADD_ALERT_SUCCESS,
  ADD_ALERT_DANGER,
  ADD_ALERT_WARNING,
  REMOVE_ALERT
 } from './actionTypes';
import AlertActions from './actions';

const loadingEpic = (action$: any, state$:any) => action$.pipe(
  ofType(ADD_ALERT_SUCCESS,ADD_ALERT_DANGER,ADD_ALERT_WARNING  ),
  delay(5000),
  mapTo(AlertActions.remove()
  ),
);

export default [
  loadingEpic
]
