import { SET, FETCH, FETCH_SUCCESS, FETCH_FAILURE,
  SET_USER, SET_REDIRECTED_LOGIN , DISABLED_REDIRECT_LOGIN } from './actionTypes';
import IState from '../../models/state.model';
import IAction from '../../models/action.model';


const defaultStore: IState = {
  username: '',
  isAuthenticated: false,
  userInfo:{},
  isRedirectedForLogin: false,
  pedidoInfo:{}
}

export default function user(
  state: IState = defaultStore,
  action: any,
) {
  switch (action.type) {
    case 'SET_IS_AUTHENTICATED': 
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case SET: 
      return {
        ...state,
        username: action.payload
      };

    case FETCH: 
      return {
        ...state
      };
    
    case FETCH_SUCCESS: 
      return {
        ...state,
        username: action.payload
      }
    
    case FETCH_FAILURE: 
      return {
        ...state
      }

    case SET_USER: 
      return {
        ...state,
        userInfo:action.payload,
        isAuthenticated:true
      }
    case SET_REDIRECTED_LOGIN:
      console.log("action.payload vin SET_REDIRECT_LOGIN",action.payload )
      return {
        ...state,
        isRedirectedForLogin:true,
        pedidoInfo: action.payload
      }
      case DISABLED_REDIRECT_LOGIN:
        return {
          ...state,
          isRedirectedForLogin:false,
          pedidoInfo:{}
        }
      

    default:
      return state;
  }
}
