import React, { lazy,useEffect,useState, useCallback} from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import OpenRoute from "./components/OpenRoute";

import { useDispatch, useMappedState } from 'redux-react-hook';


// const Login = lazy(() => import('./scenes/Login'));
const SignUp = lazy(() => import('./scenes/SignUp'));
const Home = lazy(() => import('./scenes/Home'));


const Perfil = lazy(() => import('./scenes/Perfil'));

const Search = lazy(() => import('./scenes/Search'));
const Templates = lazy(() => import('./scenes/Templates'));
const Carta = lazy(() => import('./scenes/Carta'));
const PedidoDetalle = lazy(() => import('./scenes/PedidoDetalle'));
const SearchBusiness = lazy(() => import('./scenes/SearchBusiness'));


export default ({ childProps }:any) =>
    <Switch>
       
        <Redirect exact from="/" to="/maps" />
        <OpenRoute path='/c/:slug/:category?' exact component={Carta} props={childProps} />
        <OpenRoute path='/p/:cod_pedido_comercial' exact component={PedidoDetalle} props={childProps} />
        <OpenRoute path='/negocios/buscar/:category?' exact component={SearchBusiness} props={childProps} />
        <OpenRoute path='/maps/:category?' exact component={SearchBusiness} props={childProps} />
        <AuthenticatedRoute path="/mi-perfil" exact component={Perfil} props={childProps} />
        <UnauthenticatedRoute path='/login' exact component={SignUp} props={childProps} />
        {/* <UnauthenticatedRoute path='/sign-up' exact component={SignUp} props={childProps} /> */}
        {/* <AuthenticatedRoute path='/home' exact component={Home} props={childProps} /> */}

        {/* <AuthenticatedRoute path="/search" exact component={Search} props={childProps} /> */}
        {/* <AuthenticatedRoute path="/templates" exact component={Templates} props={childProps} /> */}
    </Switch>;

