export const SET = 'SET';
export const FETCH = 'FETCH';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const SET_USER = 'SET_USER';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_REDIRECTED_LOGIN = 'SET_REDIRECTED_LOGIN';
export const DISABLED_REDIRECT_LOGIN = 'DISABLED_REDIRECT_LOGIN';
export const GET_PARAMETERS = 'GET_PARAMETERS';
export const SET_PARAMETERS = 'SET_PARAMETERS';

