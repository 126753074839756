import { SET, FETCH, FETCH_SUCCESS, FETCH_FAILURE } from './actionTypes';
import IState from '../../models/state.model';
import IAction from '../../models/action.model';


const defaultStore: any = {
  typeHeader: 'UnAuth'
}

export default function header(
  state = defaultStore,
  action: any,
) {
  switch (action.type) {
    case SET: 
      console.log("Se activo el cambio prron",action )
      return {
        ...state,
        typeHeader: action.payload
      };

    case FETCH: 
      return {
        ...state
      };
    
    case FETCH_SUCCESS: 
      return {
        ...state,
        typeHeader: action.payload
      }
    
    case FETCH_FAILURE: 
      return {
        ...state
      }

    default:
      return state;
  }
}
