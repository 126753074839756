import { SET, FETCH, GET_PARAMETERS, SET_PARAMETERS} from './actionTypes';
// import IState from '../../models/state.model';
import IAction from '../../models/action.model';
interface IState {
  parameters: any;
}


const defaultStore: IState = {
  parameters:{}
}

export default function parameters(
  state: IState = defaultStore,
  action: any,
) {
  switch (action.type) {
    case SET_PARAMETERS:
      return {
        ...state,
        parameters:action.payload
      }
      

    default:
      return state;
  }
}
