import { OPEN_LOADING, CLOSE_LOADING, OPEN_LOADING_SUCCESS } from './actionTypes';

const open = (payload?: any) => {
  return {
    type: OPEN_LOADING
  };
};

const openSuccess = (payload?: any) => {
  return {
    type: OPEN_LOADING_SUCCESS
  };
};

const close = (payload?: any) => {
  return {
    type: CLOSE_LOADING
  };
};

const LoadingActions = {
  open,
  close,
  openSuccess
}

export default LoadingActions;
