import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import loadingReducer from "./loading/reducer";
import headerReducer from "./header/reducer";
import parametersReducer from "./parameters/reducer";
import alertReducer from "./alert/reducer";

// import adnReducer from "./adn/reducer";
// import dpsReducer from "./dps/reducer";
// import cotizacionReducer from "./cotizacion/reducer";
// import formulaReducer from "./formula/reducer";
// import profilingReducer from "./profiling/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  header:headerReducer,
  parameters: parametersReducer,
  alert:alertReducer,
  // alert: alertReducer,
  // adn: adnReducer,
  // dps: dpsReducer,
  // cotizacion: cotizacionReducer,
  // formula: formulaReducer,
  // profiling: profilingReducer
})

export default rootReducer;
